import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { EuiSpacer } from '@elastic/eui';
import VideoCard from '../../../../components/Advanced_User/Videos/video-vpn-fritzbox';
export const _frontmatter = {
  "title": "VPN Tunnel",
  "path": "/Advanced_User/VPN_AVM_Fritzbox/Video/",
  "dateChanged": "2017-11-27",
  "author": "Mike Polinowski",
  "excerpt": "General information for the Fritz.box VPN function",
  "image": "./AU_SearchThumb_VPN.png",
  "social": "/images/Search/AU_SearchThumb_VPN.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_Advanced_VPN_white.webp",
  "chapter": "Advanced User"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "vpn-tunnel",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#vpn-tunnel",
        "aria-label": "vpn tunnel permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`VPN Tunnel`}</h1>
    <h2 {...{
      "id": "general-information-for-the-fritzbox-vpn-function",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#general-information-for-the-fritzbox-vpn-function",
        "aria-label": "general information for the fritzbox vpn function permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`General information for the Fritz.box VPN function`}</h2>
    <p>{`In order for the Fritz! Box to become the VPN server, first check `}<strong parentName="p">{`System / Update`}</strong>{`, if the latest firmware version is installed. Then, make sure that it is permanently connected to the Internet and that the connection is not disconnected when it is inactive. In order to be able to access the router or the VPN server on the way, you must know its address. Since the address changes every 24h, however, you could theoretically register with a DDNS provider to get a fixed Internet address. However, since your INSTAR camera already uses a built-in DDNS service, you can use the address under Network DDNS in the web interface of the camera.`}</p>
    <EuiSpacer mdxType="EuiSpacer" />
    <VideoCard mdxType="VideoCard" />
    <EuiSpacer mdxType="EuiSpacer" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      